import React, { Component } from 'react'
import { about, aboutPage } from '../../data-store/config'
 import styles from "../about/About.module.scss"
export default class About extends Component {
  render() {
    document.title="Gaya Honda | About";
    return (
      <div>
        <section className={styles.short_info}>
        <div className="container">
          <div className={styles.info_wrap}>
            <div className={styles.info}>
              {/* <h5>About us</h5> */}
              <h1>{about.name}</h1>
              <p>{aboutPage.paraOne}</p>
              <p>{aboutPage.paraTwo}</p>
              <p>{aboutPage.paraThree}</p>
              <p>{aboutPage.paraFour}</p>
              <p>{aboutPage.paraFive}</p>
            </div>
            <div className={styles.imge}>
              <img className={styles.desktopimg} src="assets/gayahondabanner.webp" alt="" />
              <img className={styles.mobImg} src="assets/ab-bg.png" alt="" />
              {/* <h2>We are here since <span>2017</span></h2> */}
            </div>
          </div>
        </div>
      </section>
      </div>
    )
  }
}
