import React, { Component } from 'react'
import { companyDetails, footerInfo } from '../../data-store/config'
import styles from "../footer/Footer.module.scss"
export class Footer extends Component {
  render() {
    return (
      <div>
        <footer className={styles.footer}>
          <div className="container">
            <div className={styles.footer_wrap}>
              {footerInfo.map((ele)=>{
                return(
                  <>
                  <div className={styles.item}>
                    <h1>{ele.head}</h1>
                    <ul>{ele.listes.map(item => <li><a href={item.url}>{item.name}</a></li>)}</ul>
                  </div>
                  </>
                )
              })}
             <div className={styles.item}>
             <h1>contact</h1>
              <ul>
                <li> <i class="fa-solid fa-phone"></i> {companyDetails.phoneNO}</li>
                <li><i class="fa-solid fa-envelope"></i>  {companyDetails.email}</li>
                <li className={styles.location}> Gewal Bigha More,West Church Road, <br/> Gaya, Bihar-823001</li>
              </ul>
             </div>

            </div>
            <div className={styles.footer_bottom}>
              <p>© {new Date().getUTCFullYear()} Gaya Honda</p>
              <p>Designed by <a target="_blank" href='https://www.datavedam.com' rel = "noopener noreferrer">Datavedam</a></p>
            </div>
          </div>
        </footer>
      </div>
    )
  }
}

export default Footer
