import React, { Component } from 'react'
import { amcDetails } from '../../data-store/config';
import styles from "../finance/Finance.module.scss"
export default class Finance extends Component {
    constructor(props) {
        super(props);
        this.state = {
          selectedModel: '',
          otherModel: '',
          selectedYear: '',
          serviceType: '',
          registrationNo: '',
          name: '',
          email: '',
          contactNo: '',
          message: '',
          errors: {}
        };
    
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
      }
    
      handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({
          [name]: value
        });
      }
    
      handleSubmit(event) {
        event.preventDefault();
        const { selectedModel, otherModel, selectedYear, serviceType, registrationNo, name, email, contactNo, message } = this.state;
    
        let errors = {};
    
        if (!selectedModel && !otherModel) {
          errors.selectedModel = 'Please select a model or enter your own';
        }
  
    
        if (!serviceType) {
          errors.serviceType = 'Please select a service type';
        }
    
        if (!registrationNo) {
          errors.registrationNo = 'Please enter a registration number';
        }
    
        if (!name) {
          errors.name = 'Please enter your name';
        }
    
        if (!email) {
          errors.email = 'Please enter your email';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
          errors.email = 'Please enter a valid email';
        }
    
        if (!contactNo) {
          errors.contactNo = 'Please enter your contact number';
        } else if (!/^\d{10}$/.test(contactNo)) {
          errors.contactNo = 'Please enter a valid 10-digit contact number';
        }
    
        if (!message) {
          errors.message = 'Please enter a message';
        }
        this.setState({ errors });
    
        if (Object.keys(errors).length === 0) {
          // Do something with form data, such as send it to a server or display it in the UI
          console.log(this.state);
          // Reset form fields
          this.setState({
            name: '',
            email: '',
            phone: '',
            product: '',
            message: '',
            errors: {}
          });
        }

      }
  render() {
    const { selectedModel, otherModel,  selectedYear, serviceType, registrationNo, name, email, contactNo, message, errors } = this.state;
    return (
      <div>
        <section className={styles.booking}>
            <div className="container">
            <div className={styles.heading}>
            <h1>LOOKING FOR EASY FINANCE OPTIONS?</h1>
          </div>
          <div className={styles.wrap}>
          <div className={styles.service_benifit}>
            <div className={styles.top_info}>
            <h2>REQUIRED DOCUMENTS</h2>
            <ul>
              {amcDetails.map(ele => <li key={ele.name}>{ele.name}</li>)}
            </ul>
            </div>
            <div className={styles.info}>
            <div className={`${"accordion"} ${styles.acroding}`} id="accordionExample">
                <div className={`${"accordion-item"} ${styles.accroding_item}`}>
                    <h2 className={`${"accordion-header"} ${styles.head}`} id="headingOne">
                    <button className={`${"accordion-button"} ${styles.button}`} type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    <span><i class="fa-sharp fa-solid fa-question"></i></span> POA (Proof of Address)
                    </button>
                    </h2>
                    <div id="collapseOne" className={`${"accordion-collapse collapse show"} ${styles.show}`} aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div className={`${"accordion-body"} ${styles.body}`}>
                     <ul>{amcDetails.map(ele => <li key={ele.name}>{ele.name}</li>)} </ul>
                    </div>
                    </div>
                </div>
                <div className={`${"accordion-item"} ${styles.accroding_item}`}>
                    <h2 className={`${"accordion-header"} ${styles.head}`} id="headingTwo">
                    <button className={`${"accordion-button collapsed"} ${styles.button}`} type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    <span><i className="fa-sharp fa-solid fa-question"></i></span>  POI (Proof of Identity)
                    </button>
                    </h2>
                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div className={`${"accordion-body"} ${styles.body}`}>
                    <ul>
                            {amcDetails.map(ele => <li key={ele.name}>{ele.name}</li>)}
                            </ul>
                    </div>
                    </div>
                </div>
                </div>

            </div>
          </div>

          {/* form */}
          <form >
            <h2>ENQUIRE NOW</h2>
            <div className="form-group">
              <select id="selectedModel" name="selectedModel" value={selectedModel} onChange={this.handleChange}>
                <option value="">-- Select a model --</option>
                <option value="model1">Model 1</option>
                <option value="model2">Model 2</option>
              </select>
              {errors.selectedModel && <div className={styles.error}>{errors.selectedModel}</div>}
            </div>

            <div className="form-group">
              <select id="selectedYear" name="selectedYear" value={selectedYear} onChange={this.handleChange}>
                <option value="">-- YEAR OF PURCHASE --</option>
                <option value="model1">1985</option>
                <option value="model2">1999</option>
              </select>
              {errors.selectedYear && <div className={styles.error}>{errors.selectedYear}</div>}
            </div>

            {/* registrationNo  */}
            <div className="form-group">
              <input type="text" placeholder='enter registration No' id="registrationNo" name="registrationNo" value={registrationNo} onChange={this.handleChange} />
              {errors.registrationNo && <div className={styles.error}>{errors.registrationNo}</div>}
            </div>

          {/* Personal details */}
            <div className={styles.personalDetails}>
            <div className="form-group">
              <input type="text" placeholder='Your name' id="name" name="name" value={name} onChange={this.handleChange} />
              {errors.name && <div className={styles.error}>{errors.name}</div>}
            </div>    
            <div className="form-group">
              <input type="text" placeholder='E-mail' id="email" name="email" value={email} onChange={this.handleChange} />
              {errors.email && <div className={styles.error}>{errors.email}</div>}
            </div>   
            <div className="form-group">

              <input type="text" placeholder='Contact No' id="contactNo" name="contactNo" value={contactNo} onChange={this.handleChange} />
              {errors.contactNo && <div className={styles.error}>{errors.contactNo}</div>}
            </div>    
            <div className="form-group">
              <input type="text" placeholder='Message' id="message" name="message" value={message} onChange={this.handleChange} />
              {errors.message && <div className={styles.error}>{errors.message}</div>}
            </div>
            </div>
            <button type="submit" onClick={this.handleSubmit}>Submit</button>
          </form>
          </div>
            </div>
         
        </section>

      </div>
    )
  }
}
