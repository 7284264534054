import React, { Component } from 'react'
import Slider from "react-slick";
import { about, bannerImg, MobileBannerImg, recentBike } from '../../data-store/config';
import styles from "../home/Home.module.scss"
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
export default class Home extends Component {
  constructor (props){
    AOS.init({
      once:true,
    });
    super(props);
    this.sate={
      
      bannerArrow:""
    }
  }
  render() {
    const settings = {
      dots: true,
      infinite: false,
      fade:true,
      speed: 1500,
      autoplay: true,
      autoplaySpeed: 4000,
      arrows:false,
      responsive: [
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
      dots: false,

          }
        }
      ]
    };

    const mobileBnner = {
      dots: false,
      infinite: true,
      speed: 1500,
      autoplay: true,
      autoplaySpeed: 4000,
      arrows:false
    };
    return (
      <div>
      <section className={styles.banner}>
       <Slider {...settings}>
        {bannerImg.map(ele=><div key={ele.url}>  <img  src={ele.url} alt="" /></div>)}
        </Slider>
      </section>

      <section className={styles.mobilebanner}>
       <Slider {...mobileBnner}>
        {MobileBannerImg.map(ele=><div key={ele.url}>  <img  src={ele.url} alt="" /></div>)}
        </Slider>
      </section>
      {/* Banner end */}

      <section className={styles.recentItem}>
        <div className="container">
          <div className={styles.heading}>
            <h1 >Wlcome to The home of <span style={{textTransform:"capitalize" , color:"red"}}>Gaya Honda</span>  </h1>
            <h3 >Make life A Ride</h3>
          </div>

          <div className={styles.bike_wrap}>
            {recentBike.map((item)=>{
              return(
                <div className={styles.item}key={item.name}>
                  <div className={styles.imges} >
                  <img src={item.img} alt="" />
                  </div>
                  <h2 >Honda <span>{item.name}</span> </h2>
                  <p >{item.para}</p>
                  <a  href={item.url} > Explore Now <i className='fa-solid fa-arrow-right'></i> </a>
                </div>
              )
            })}
          </div>
        </div>
      </section> 


      <section className={styles.short_info}   >
        <div className="container">
          <div className={styles.info_wrap}>
            <div className={styles.info}>
              <h5 >About us</h5>
              <h1 >{about.name}</h1>
              <p >{about.para}</p>
              <Link to="/about">{about.button} <i className='fa-solid fa-arrow-right'></i> </Link>
              
            </div>
            <div className={styles.imge} >
              <img src="assets/bg.webp" alt="" />
              <h2>We are here since <span>2017</span></h2>
            </div>
          </div>
        </div>
      </section>
      
     <section className={styles.hero}>
        <div className="container">
          <div className={styles.items_wrap}>
          <div className={styles.item}>
            <img style={{width:"15%"}} src="assets/no:1.png" alt="" />
            <h1>No:1 </h1>
            <p>Sales & Services</p>
          </div>
          <div className={styles.item}>
          <img style={{width:"15%"}} src="assets/100.png" alt="" />
            <h1>100% </h1>
            <p>Early Deliveries</p>
          </div>
          <div className={styles.item}>
          <img style={{width:"15%"}} src="assets/customer.png" alt="" />
            <h1>500+</h1>
            <p>Happy Coustomers</p>
          </div>
          </div>
         
        </div>
      </section>

      <section className={styles.delar}>
        <div className="container">
          <div className={styles.info_wrap}>
            <div className={styles.info}>
              <h1>Authorized <span>{about.name}</span> Dealership </h1>
              <p>{about.arthPara}</p>
            </div>
            <div className={styles.imge}>
              <img src="assets/delar.webp" alt="" />
            </div>
          </div>
        </div>
      </section>

      </div>
    )
  }
}
