import React, { Component } from 'react'
import { Link, NavLink } from 'react-router-dom';
import Footer from "../footer/Footer"
import {  companyDetails, Navmenu } from '../../data-store/config'
import styles from "../navbar/Navbar.module.scss"

export default class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      colorChange: false,
    };
    this.myFunction = this.myFunction.bind(this);
  }
  componentDidMount() { window.addEventListener("scroll", this.changeNavbarColor);}
  componentWillUnmount() {window.removeEventListener("scroll", this.changeNavbarColor); }

  myFunction() {
    this.setState((prevState) => ({
      showMenu: !prevState.showMenu,
    }));
    const body = document.querySelector('body');
    body.classList.toggle('menu-open');
  }

  changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      this.setState({
        colorChange: true,
      });
    } else {
      this.setState({
        colorChange: false,
      });
    }
  };
  render() {
    return (
      <div>
         <header>
            <div className={styles.top_nav}>
              <div className="container">
              <h1>Book Your Service on Whatsapp <i className='fa-brands fa-whatsapp'></i> {companyDetails.phoneNO} </h1>
               </div>
            </div>
            <nav className={`${styles.navbar} ${
            this.state.colorChange ? styles.colorChange : ""
          }`} >
              <div className="container">
                <div className={styles.nav_wrap}>
                  <div className={styles.logo}><a href="/">  <img src={companyDetails.logoUrl} alt="" /></a></div> 
                  <div className={styles.menu}>
                    <h1>{companyDetails.name}</h1>
                    <ul>{Navmenu.map(ele=> <li key={ele.name}><NavLink to={ele.url} >{ele.name}</NavLink></li>)}</ul>
                  </div>
                </div>
              </div>
            </nav>
        </header>

      {/* This is mobile Menu */}
        <div className={styles.mobile}> 
          <nav  className={`${styles.mobile_nav} ${this.state.colorChange ? styles.colorChange : ""}`}>
            <div className="container">
              <div className={styles.nav_bar}>
                <div className={styles.logo}>
                  <Link to="/"> <img src="assets/logo.png" alt="" /></Link>
                </div>
                <h1>{companyDetails.name}</h1>
                <div className={styles.menu}>   
                <div className={styles.toggle_button}>
                  <button onClick={this.myFunction} className={this.state.showMenu ? styles.menuicons : ""}>
                    <span></span>
                    <span></span>
                    <span></span>
                  </button>
                </div>
                </div>
              </div>
            </div>
          </nav>
          <div className={styles.top_nav}>
            <div className="container">
            <h1>Book Your Service on Whatsapp <i className='fa-brands fa-whatsapp'></i> {companyDetails.phoneNO} </h1>
            </div>
          </div>
        </div>

       {/* This is toggle menu */}
        <div className={`${styles.toggle_menu}  ${  this.state.showMenu ? styles.actives : ""}`}>
            <div className="container">
          <div className={styles.menus}>
          <ul className={styles.navbar_nav}>{Navmenu.map(ele=> <li className={styles.nav_item} key={ele.name}>
            <a onClick={this.myFunction} className={this.state.showMenu ? styles.menuicons : "nav_a"} href={ele.url}>{ele.name}</a></li>)}</ul>
            </div>
          </div>
        <Footer/>
        </div>
      </div>
    )
  }
}
