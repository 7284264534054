export const companyDetails = {
  logoUrl: "assets/logo.png",
  name: "Gaya Honda",
  email: "sales@gayahonda.in",
  phoneNO: "+91 7480091374",
  address:"Gewal Bigha More,West Church Road, Gaya, Bihar-823001"
};
export const Navmenu = [
  {name:"Home", url: "/"},
  {name:"About us", url: "/about"},
  // {name:"Gallery", url: "/gallary"},
  // {name:"Models", url: "/models"},
  {name:"Services", url: "/service"},
  // {name:"Finance", url: "/finace"},
  {name:"Contact us", url: "/contact"},
]
export const bannerImg = [
  {url:"assets/gayahondabanner.webp"},
  {url:"assets/bg-5.webp"},
  {url:"assets/bg-3.webp"},
  // {url:"assets/mb-test.webp"},
  {url:"assets/bg-2.webp"},
  {url:"assets/bg-6.webp"},
  {url:"assets/bg-4.webp"},

  
]

export const MobileBannerImg = [
  {url:"assets/ab-bg.webp"},
  {url:"assets/mb-bg-test.webp"},
  {url:"assets/mb-bg-02.webp"},
  {url:"assets/mb-bg-03.webp"},
  {url:"assets/mb-bg-04.webp"},
  {url:"assets/mb-bg-05.webp"},

  
]
export const  recentBike = [
  {img:"assets/Bike-1.webp",url:"", name:" cb200x " , para:"Explore your city. Plan the ideal adventurous weekend or take a detour on your way back home from work. It’s time to explore life with every ride with the new CB200X."},
  {img:"assets/Grazia_sports_1200.webp",url:"", name:"Grazia 125 " , para:"How do you add excitement to something that is already a stunning genius? By giving shape to its sporty avatar. Here is presenting the Grazia 125. Now with its stunning, intelligent features, enjoy the rush you always wanted in your ride"},
  {img:"assets/cd300.webp",url:"", name:"cb300f " , para:"Get ready to rule the urban streets in a formidable fashion. The CB300F is an uncompromising street fighter that is powerful and agile with a masculine toned look that seems straight out of a workout."},

]
export const about= {
  name: "Gaya Honda",
  para:"Welcome to Gaya Honda, your premier destination for top-quality Honda motorcycles and accessories in the heart of the city. Founded in 2017, we have been providing exceptional service and unmatched expertise to our customers for over 5 years now.",
  button:"More Info",
  arthPara:"HONDA MOTORCYCLE & SCOOTER INDIA PVT. LTD. (HMSI) is the wholly-owned subsidiary of Honda Motor Company, Japan – the world’s no.1 two-wheeler company. Commencing its Indian 2wheeler operations in May 2001, honda in the last two decades has grown to become India’s 2nd largest two-wheeler company with over 55 million happy customers."
} 

export const aboutPage={
  paraOne:"Welcome to Gaya Honda, your premier destination for top-quality Honda motorcycles and accessories in the heart of the city. Founded in 2017, we have been providing exceptional service and unmatched expertise to our customers for over 5 years now.",
  paraTwo:"At Gaya Honda, we are passionate about motorcycles and everything related to them. Our team of knowledgeable and experienced professionals is dedicated to helping you find the perfect bike that suits your riding style, preferences, and budget. Whether you are a seasoned rider or a beginner, we have a wide selection of motorcycles and accessories to cater to your needs.",
  paraThree:"Our showroom features the latest models from Honda. We also offer a wide range of accessories, from helmets and riding gear to aftermarket parts and performance upgrades, to enhance your riding experience and make your bike stand out from the crowd.",
  paraFour:"At Gaya Honda, we believe in building long-lasting relationships with our customers. We pride ourselves on providing exceptional customer service, from the moment you step into our showroom to the day you ride off on your new bike. Our team of experts is always available to answer any questions you may have, provide professional advice, and help you with any service and maintenance needs",
  paraFive:"We are committed to ensuring your satisfaction and making your motorcycle ownership experience as enjoyable and hassle-free as possible. Visit us today and experience the Gaya Honda difference for yourself!"
}

export const heroInfo =[
  {name: "No:1", para:"Sales & service", icon:""}
]

export const footerInfo = [
  {head: "other Links", 
listes:[
  {name:"careers",url:"/careers"},
  {name:"Events",url:"/events"},
  {name:"About us",url:"/about"},
]
},
{head: "social Links", 
listes:[
  {name:"Facebook",url:"https://www.facebook.com/gaya.honda.5?paipv=0&eav=AfZCz5DjEECLMeRJ__GZFTj4oIdtpqct43QHkYO-QJIk6mVLY4inKzlbXaiPaRECYSk&_rdr"},
  {name:"Instagram",url:"/events"},
  {name:"Twitter",url:"/about"},
]
}
]


export const serviceBenifit = [
  {name:"Quality Repair with Honda Trained & Certified Technicians"},
  {name:"Genuine Parts, Lubricants & Accessories for enhancing vehicle performance and overall life."},
  {name:"Use of computerised systems /programs for vehicle performance check and detecting malfunctioning parts. 4) Availability of Honda Exclusive Motorcycle Communication System- MCS tool"},
  {name:"Vehicle Repair History and Parts replacement details gets stored in our system."},
  {name:"Regular service is to be done only at Honda authorised workshop to continue Normal/Extended Warranty of your vehicle."},
  {name:"Exclusive cashless Insurance Claim facility"},
  {name:"Exclusive facility of 6 months Engine repair warranty with our engine health insurance program."},
  {name:"Vehicle service can be done at our automated workshop as per service shedule and HMSI recommend guidelines."},
  {name:"Pick Up & Drop facility* available."},


]

export const amcDetails =[
  {name:"Savings up to 30%"},
  {name: "3 Maintenance services*"},
  {name: "2 Additional free washing*"},
  {name: "10% discount on Labour charges (except accidental)"},
  {name: "5% discount on spare parts and accessories"},
  {name: "5% discount on Honda Genuine Engine oil"},
  {name: "Engine Health report through MCS Tool"},

]