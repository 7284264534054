
import './App.scss';
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Home from './components/home/Home';
// import Models from './components/models/Models';
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';
import About from './components/about/About';
import Gallary from './components/gallary/Gallary';
import Contact from './components/contact/Contact';

import Service from './components/services/Service';
import BookService from './components/services/book_service/BookService';
import Finance from './components/finance/Finance';
import { useEffect, useState } from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';

function App() {
  return (
    <ParallaxProvider>
    <BrowserRouter>
     <ScrollToTop/>
    <Navbar/>
    <Routes>
        <Route index element={<Home/>} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/gallary" element={<Gallary />} />
        <Route path="/service" element={<Service />} >
           <Route index element={<BookService/>} />
            <Route path='book_service' element={<BookService/>} />
            <Route path='amc' element={<BookService/>} />
            <Route path='extended_warranty' element={<BookService/>} />
        </Route>
        <Route path="/finace" element={<Finance />} />

    </Routes>
   
    <Footer/>
    <ScrollButton />
  </BrowserRouter>
  </ParallaxProvider>
  );
}

export default App;


// rel = "noopener noreferrer";


export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth", // Optional if you want to skip the scrolling animation
    });
  }, [pathname]);

  return null;
}

export const ScrollButton = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <button
      style={{
        position: "fixed",
        right: "50px",
        bottom: "20px",
        height: "20px",
        fontSize: "1.5rem",
        zIndex: "1",
        cursor: "pointer",
        color: "#fff",
        background: "red",
        padding: "1em",
        borderRadius: "5px",
        boxShadow: "0 0 20px rgba(0, 0, 0, 0.39)",
        display: visible ? "inline" : "none",
        border:"none"
      }}
      onClick={scrollToTop}
      //
    >
      <i
        style={{ display: visible ? "inline" : "none", position: "fixed" ,right:"65px" , bottom:"35px"}}
        class="fa-solid fa-arrow-up"
      ></i>
    </button>
  );
};


