import React, { Component } from 'react'
import { companyDetails } from '../../data-store/config'
import styles from "../contact/contact.module.scss"
export default class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      phone: '',
      product: '',
      message: '',
      errors: {}
    };
  }

  handleInputChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = event => {
    event.preventDefault();
    // Validate form fields
    const errors = {};
    if (!this.state.name) {
      errors.name = 'Please enter your name.';
    }
    if (!this.state.email) {
      errors.email = 'Please enter your email address.';
    } else if (!/\S+@\S+\.\S+/.test(this.state.email)) {
      errors.email = 'Please enter a valid email address.';
    }
    if (!this.state.phone) {
      errors.phone = 'Please enter your phone number.';
    } else if (!/^\d{10}$/.test(this.state.phone)) {
      errors.phone = 'Please enter a 10-digit phone number.';
    }
    if (!this.state.product) {
      errors.product = 'Please select a product.';
    }
    if (!this.state.message) {
      errors.message = 'Please enter a message.';
    }
    this.setState({ errors });

    // Submit form if no errors
    if (Object.keys(errors).length === 0) {
      // Do something with form data, such as send it to a server or display it in the UI
      console.log(this.state);
      // Reset form fields
      this.setState({
        name: '',
        email: '',
        phone: '',
        product: '',
        message: '',
        errors: {}
      });
    }
  };
  render() {
    document.title="Gaya Honda | contact";
    const { errors } = this.state;
    return (
      <div>
        <section className={styles.contact}>
          <div className="container">
            <h1>gaya honda</h1>

            <div className={styles.contact_info}>
              <h2><i class="fa-sharp fa-solid fa-location-dot"></i>  {companyDetails.address}</h2>
              <h2><i class="fa-solid fa-phone"></i> {companyDetails.phoneNO}</h2>
            </div>
            <div className={styles.wrap}>
           
            <iframe title="This is a unique title" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3622.2906594696706!2d84.98919462835931!3d24.785498833198886!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f32b37e43398b9%3A0x488d151c8ca1c160!2sGaya%20Honda!5e0!3m2!1sen!2sin!4v1678564318742!5m2!1sen!2sin"  allowFullScreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
           
            <form onSubmit={this.handleSubmit}>
                <div>
                  <label htmlFor="name">Name:</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={this.state.name}
                    onChange={this.handleInputChange}
                  />
                  {errors.name && <div className={styles.error}>{errors.name}</div>}
                </div>
                <div>
                  <label htmlFor="email">Email:</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleInputChange}
                  />
                  {errors.email && <div className={styles.error}>{errors.email}</div>}
                </div>
                <div>
                  <label htmlFor="phone">Phone:</label>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    value={this.state.phone}
                    onChange={this.handleInputChange}
                  />
                  {errors.phone && <div className={styles.error}>{errors.phone}</div>}
                </div>
                <div>
                  <label htmlFor="product">Product:</label>
                  <select
                    id="product"
                    name="product"
                    value={this.state.product}
                    onChange={this.handleInputChange}
                  >
                    <option value="">Select a product</option>
                    <option value="Product A">Product A</option>
                    <option value="Product B">Product B</option>
                    <option value="Product C">Product C</option>
                  </select>
                  {errors.product && <div className={styles.error}>{errors.product}</div>}
                  <div>
                  <label htmlFor="message">Message:</label>
                  <textarea
                    id="message"
                    name="message"
                    value={this.state.message}
                    onChange={this.handleInputChange}
                  />
                </div>
                <button type="submit">Submit</button>
                </div>
        
           </form>
            </div>
          </div>
        </section>
      </div>
    )
  }
}
