import React, { Component } from 'react'
import { Link, Outlet } from 'react-router-dom'
import styles from "../services/Service.module.scss"
export default class Service extends Component {
  render() {
    document.title="Gaya Honda | Service";

    return (
      <div>
        <section className={styles.service}>
          <div className="container">
            <nav className={styles.nav_motors}>
            <div className={styles.navbar}>
              <ul>
              <li><Link to="book_service">Book A Service</Link></li>
                <li><Link to="amc">Amc</Link></li>
                <li><Link to="extended_warranty">Extended  Warranty</Link></li>
              </ul>
            </div>
          </nav>
            <Outlet/>
          </div>
        </section>
      </div>
    )
  }
}
